
:not(.react-datepicker__navigation):not(.selectpicker) + button:not(.close):not(.btn-nostyling):not(.input-group):not(.btn-link):not(.note-btn):not(.tox-tbtn):not(.tox-mbtn):not(.tox-statusbar__wordcount):not(.tox-button):not(.gm-control-active):not(.gm-svpc):not(.gm-ui-hover-effect),
.btn-primary:not(.react-datepicker__navigation):not(.close):not(.input-group):not(.btn-link):not(.tox-tbtn):not(.gm-control-active):not(.gm-svpc):not(.gm-ui-hover-effect),
.btn-secondary:not(.react-datepicker__navigation):not(.close):not(.input-group):not(.btn-link):not(.tox-tbtn):not(.gm-control-active):not(.gm-svpc):not(.gm-ui-hover-effect),
.btn-default:not(.react-datepicker__navigation):not(.close):not(.input-group):not(.btn-link):not(.tox-tbtn):not(.tox-mbtn):not(.gm-control-active):not(.gm-svpc):not(.gm-ui-hover-effect),
.btn-danger:not(.react-datepicker__navigation):not(.close):not(.toggle):not(.toggle-on):not(.toggle-off):not(.toggle-handle):not(.navbar-toggle):not(.note-btn):not(.input-group):not(.btn-link):not(.tox-tbtn):not(.gm-control-active):not(.gm-svpc):not(.gm-ui-hover-effect),
.btn-light:not(.react-datepicker__navigation):not(.close):not(.toggle):not(.toggle-on):not(.toggle-off):not(.toggle-handle):not(.navbar-toggle):not(.note-btn):not(.input-group):not(.btn-link):not(.tox-tbtn):not(.gm-control-active):not(.gm-svpc):not(.gm-ui-hover-effect),
{
  font-size: 1rem;
  padding: calcRem(8px) calcRem(12px);
  min-width: calcRem(75px);
  min-height: calcRem(28px);
  border-radius: 0 !important;
  color: $kleur-donkersteBlauw;
  background-color: $kleur-grijsCyan;
  border: 1px solid $kleur-grijsBlauw;
  box-shadow: inset 0 1px 0 rgba(255,255,255,.15),0 1px 1px rgba(0,0,0,.075);
  -webkit-box-shadow: inset 0 1px 0 rgba(255,255,255,.15),0 1px 1px rgba(0,0,0,.075);

  span {
   // font-size: calcRem(18px);
  }
  &.btn-xs {
    margin-bottom: 0 !important;
    padding: 0 4px;
    min-width: calcRem(34px);
  }
  &.btn-sm {
    min-width: calcRem(34px);
    font-size: calcRem(17px);
    padding: calcRem(5px) calcRem(12px);
  }
  &.btn-lg {
    padding: calcRem(8px) calcRem(32px);
    font-weight: bold;
  }

  &:hover {
    color: $kleur-donkersteBlauw;
    background-color: $kleur-grijsBlauw2;
    border: 1px solid $kleur-grijsBlauw;
  }

  &.btn-primary {
    color: $kleur-donkersteBlauw;
    background-color: $kleur-geel;
    border: 1px solid darken($kleur-geel, 10%);

    &:hover {
      color: $kleur-donkersteBlauw;
      background-color: darken($kleur-geel, 10%);
    }
  }
  &.btn-info {
    color: #fff;
    background-color: $kleur-zachtCyan;
    border-color: $kleur-zachtCyan2;
    &:hover {
      color: #fff;
      background-color: $kleur-sterkCyan;
      border-color: $kleur-sterkCyan2;
    }
  }
  &.btn-danger {
    color: #fff;
    background-color: $kleur-sterkRood;

    &:hover {
      background-color: $kleur-sterkRood2;
    }
  }

}
.btn-light {
  background-color: white !important;
  border-color:#ccc  !important;
  font-weight: normal  !important;
  border-radius: 0 !important;
  &:hover {
    background-color: darken(white, 5%)  !important;
    border-color: darken(#ccc, 5%)  !important;
  }
}